a {
  color: #b3d9f4;
}
.App {
  height: 100vh;
  background-color: #000013;
  overflow: scroll;
  text-align: left;
}

.container {
  width: 100%;
  padding: 0%;
  background-color: #5d7985;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header-container {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-content:space-around;
  gap: 20px;
}

.header {
  margin: 0 0 0 10px;
  font-family: 'Century Schoolbook',serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5em;
  font-variant-ligatures: normal;
  font-feature-settings: "ss07";
  color:#cacaca;
}

.sub-text {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25em;
  background-color: #001738;
  color: rgb(38, 255, 0);
  outline: #1692ff 1px dashed;
  padding: 20px;
}

.content-container {
  display: flex;
}
.mint-container {
  margin-top: 0px;
  margin-left: 20px;
  color: #eda282;
}

.collection-links{
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  width: 80%;
}

.gif-container {
  width:95%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: space-between;
}

.technical {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25em;
  color: rgb(217, 217, 217);
  padding: 10%;
}

.technical h2{
  color: rgb(255, 100, 164);
}

.gradient-text {
  background: -webkit-linear-gradient(left, #ffd595 20%, #91c9fa 50%, #ffff97 75%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 10s ease 0s infinite;
}

.gif-box a {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  color: rgb(222, 91, 76);
}

.gif-box {
  width: 20%;
  padding: 10px;
  background-color: #c5d3bd;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.garden-box {
  width:100vw;
  display: flex;
  justify-content: space-around;
  background-color: #537185;
  align-items: center;
  flex-flow:row nowrap;
  padding-top:50px;
  padding-bottom:50px;
}

.seed-card {
  width:25vw;
  height:25vw;
  background-size: cover;
  background-position: center;
  flex: 0.1 1 25vw;
  display:flex;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0 0 0px 5px #303d45;
}
.seed-card-link {
  text-decoration: none;
  color:#cccccc
}

.seed-card-name {
  font-size: 0.85em;
  display: none;
  margin-top:5px;
  margin-right:5px;
}

.seed-card-number {
  margin-top: 5px;
  margin-left:5px;
  border: none;
  box-shadow: none;
}

.seed-button {
  width: 0;
      height: 0;
      border-top: 3vw solid transparent;
      border-left: 3vw solid rgb(193, 173, 237);
      border-bottom: 3vw solid transparent;
}
.garden-box button{
  background-color: #f8dfc5;
  color:#8e010a;
  font-size: 2em;
  border:none;
  padding: 5px;
}

.grinder-gif {
  width: 100%;
}

.cta-button {
  border: 0;
  width: auto;
  margin-top:10px;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  color: rgb(36, 1, 1);
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #f8932f, #dd335a);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding:20px;
  background-color: #464746;
}

.fullscreen-actions{
  transition: opacity 0.25s ease-in;
  -webkit-transition: opacity 0.25s ease-in;
}

.fullscreen-button{
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.fullscreen-button button {
  width: 50px;
  height: 50px;
  background-color: #000000;
  border-style: none;
}

.fullscreen-nav {
  position:absolute;
  font-size: 3em;
  top: 0px;
  left: 10px;
}
.fullscreen-nav a{
  text-decoration: none;
  color: rgb(255, 255, 255);
}

@media (min-width: 800px){
  .flex-wrapper{
    display:flex;
    justify-content:left;
  }
  .header {
    font-size:8em;
  }
  .mint-container {
    margin-left: clamp(100px,10vw,300px);
    margin-right: max(5vw,100px);
  }
  .seed-card-name {
    display:block;
  }
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
